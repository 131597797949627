<template>
  <div class="col-6">
    <b-form-group :label="label">
      <b-form-input v-model="input" 
      :type="type" :required="required"
      @input="$emit('input',input)" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    required: {},
    type: {
      default: "text",
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
};
</script>