<template>
  <div class="col-6">
    <b-form-group label="Categoria">
      <b-form-select
        v-model="option"
        :options="categorias"
        class="mb-3"
        value-field="id"
        text-field="nombre"
        @input="$emit('input', option)"
        required
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    value: {},
    tipo:{
      default:'proyectos',
    },
  },
  async mounted() {
    await this.getCategorias();
  },
  data() {
    return {
      option: this.value,
    };
  },
  computed: {
    ...mapGetters({
      categoriasProyectos: "categorias/categoriasProyectos",
      categoriasPropiedades: "categorias/categoriasPropiedades",
    }),
    categorias(){
      if(this.tipo==='proyectos'){
        return this.categoriasProyectos
      }
      return this.categoriasPropiedades
    }
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
    }),
  },
};
</script>