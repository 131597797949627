export const PROYECTO = {
    nombre: "",
    nombreIngles: "",
    ciudad: "",
    pais: "",
    giro: "",
    giroIngles: '',
    categoria: "",
    direccion: "",
    descripcion: "",
    descripcionIngles: '',
    galeria: [],
    posicion: null,
    destacado: false,
}

export const PROYECTO_KEYS = {
    nombre: 1,
    nombreIngles: 10,
    ciudad: 20,
    pais: 30,
    giro: 40,
    giroIngles: 50,
    categoria: 60,
    direccion: 70,
    descripcion: 80,
    descripcionIngles: 90,
}

export const FIELDS_TABLA_PROYECTO = [
    {
        key: "nombre",
        label: "Nombre",
    },
    {
        key: "direccion",
        label: "Dirección",
    },
    {
        key: "posicion",
        label: "Posicion",
    },
    {
        key: "destacado",
        label: "Destacado",
    },
    {
        key: "acciones",
        label: "Acciones",
    },
]