<template>
  <div class="row col-12">
    <label>{{ label }}</label>
    <vue-editor v-model="input" @input="$emit('input', input)" />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    label: {},
    value: {},
  },
  data() {
    return {
      input: this.value,
    };
  },
};
</script>

<style scoped>
.quillWrapper {
  width: 100%;
  height: 100%;
}
</style>
