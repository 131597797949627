<template>
    <form-propiedad />
  </template>
  
  <script>
  import formPropiedad from "@/components/propiedades/formPropiedad.vue";
  export default {
    components: {
      formPropiedad,
    },
  };
  </script>
  
  <style></style>
  