<template>
  <b-form-file
    v-model="imagen"
    :state="Boolean(imagen)"
    placeholder="Arrastra una imagen aqui..."
    drop-placeholder="Drop file here..."
    accept=".jpg, .jpeg, .png"
    @input="$emit('imageUploaded',$event)"
  />
</template>

<script>
export default {
    props:{
        value:{},
    },
    data(){{
        return {
            imagen:this.value,
        }
    }}
}
</script>

<style>
</style>