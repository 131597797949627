export const incrementPositionAndRemove = (array, index) => {
  if (index < array.length - 1) {
    array.map((x) => {
      if (x.posicion >= array[index].posicion) {
        x.posicion--;
      }
    });
  }
  array.splice(index, 1);
  return array;
};

export const getHighestPositionInArray = (array) => {
  let max = 0;
  array.map((x) => {
    if (x.posicion > max) {
      max = x.posicion;
    }
  });
  return max;
};
