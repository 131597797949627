<template>
  <div class="row col-12 d-flex justify-content-between mt-2">
    <div
      v-for="(imagen, index) in galeria"
      :key="index"
      class="d-flex flex-column justify-content-between"
    >
      <div>
        <img
          :src="imagen.url"
          alt="responsive image"
          width="300px"
          height="300px"
        />
      </div>
      <div class="mt-1 mb-1">
        <b-button
          variant="danger"
          size="sm"
          @click="
            !imagen.hasOwnProperty('path')
              ? removeImageLocal(index)
              : removeImageDb(imagen, index)
          "
          >Eliminar</b-button
        >
      </div>
      <b-form-group label="Posicion">
        <b-form-input v-model.number="imagen.posicion" type="number" />
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    galeria: {},
    removeImageLocal: {},
    removeImageDb: {},
  },
};
</script>