<template>
    <div class="container">
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="propiedadId ? handleUpdateDesarrollo() : handleAddDesarrollo()">
          <div class="row col-12">
            <UIInput v-model="propiedad.nombre" 
            label="Nombre" required :key="keys.nombre" />
            <UIInput v-model="propiedad.nombreIngles" 
            label="Nombre inglés" required :key="keys.nombreIngles" />
            <UIInput v-model="propiedad.direccion" 
            label="Direccion" required :key="keys.direccion" />
            <UIInput v-model="propiedad.ciudad" 
            label="Ciudad" required :key="keys.ciudad" />
            <UIInput v-model="propiedad.pais" 
            label="Pais" required :key="keys.pais" />
            <SeleccionarCategoria v-model="propiedad.categoria"
            :key="keys.categoria" tipo="propiedades" />
            <UIInput v-model="propiedad.giro" 
            label="Giro" required :key="keys.giro" />
            <UIInput v-model="propiedad.giroIngles" 
            label="Giro inglés" required :key="keys.giroIngles" />
          </div>
          <UIEditor label="Descripcion"
          v-model="propiedad.descripcion" :key="keys.descripcion" />
          <UIEditor label="Descripcion inglés"
          v-model="propiedad.descripcionIngles" :key="keys.descripcionIngles" />
          <div class="row col-12">
            <h5 class="mt-3">Galeria</h5>
          </div>
          <div class="row col-12" :key="formInputkey">
            <GaleriaSeleccionar v-model="imagen"
            @imageUploaded="imageUploaded($event)" />
            <GaleriaImagenes :removeImageLocal="removeImageLocal"
            :removeImageDb="removeImageDb" :galeria="propiedad.galeria" />
          </div>
          <BotonGuardar :id="propiedadId" />
        </form>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import {PROYECTO, PROYECTO_KEYS} from '@/constants/proyecto'
  import {
    incrementPositionAndRemove,
    getHighestPositionInArray,
  } from "@/helpers/arrays";
  import UIInput from '@/components/ui/input'
  import UIEditor from '@/components/ui/editor'
  import SeleccionarCategoria from '@/components/seleccionar/categoria'
  import BotonGuardar from '@/components/boton/guardar'
  import GaleriaImagenes from '@/components/galeria/imagenes'
  import GaleriaSeleccionar from '@/components/galeria/seleccionar'
  
  export default {
    props: {
      propiedadId: {
        type: String,
        required: false,
      },
    },
    components: {
      UIInput,
      SeleccionarCategoria,
      UIEditor,
      BotonGuardar,
      GaleriaImagenes,
      GaleriaSeleccionar,
    },
    data() {
      return {
        loading: false,
        formInputkey: 0,
        position: 1,
        imagen: null,
        propiedad: JSON.parse(JSON.stringify(PROYECTO)),
        keys:JSON.parse(JSON.stringify(PROYECTO_KEYS)),
      };
    },
    async mounted() {
  
      if (!this.propiedadId) return
      
      await this.getPropiedad(this.propiedadId);
      Object.keys(this.keys).forEach(k =>{
        this.keys[k]++
      })
      this.position = getHighestPositionInArray(this.propiedad.galeria) + 1;
      this.propiedad = JSON.parse(JSON.stringify(this.propiedaddb));
  
    },
    computed: {
      ...mapGetters({
        propiedaddb: "propiedades/propiedad",
      }),
    },
    methods: {
      ...mapActions({
        savePropiedad: "propiedades/savePropiedad",
        getPropiedad: "propiedades/getPropiedad",
        deletePropiedadImage: "propiedades/deletePropiedadImage",
        updatePropiedad: "propiedades/updatePropiedad",
      }),
      imageUploaded(imagen) {
        let url = URL.createObjectURL(imagen);
        this.propiedad.galeria.push({ imagen, posicion: this.position, url });
        this.imagen = null;
        this.position++;
        this.formInputkey++;
      },
      async handleAddDesarrollo() {
        this.loading = true;
        const res = await this.savePropiedad(this.propiedad);
        
        if (res) {
          this.$swal("Exito", "Propiedad creada", "success");
          this.loading = false;
          return this.$router.push({ name: "Admin Propiedades" });
        }
  
        this.$swal("Error", "Error al crear el desarrollo", "error");
        this.loading = false;
  
      },
      async handleUpdateDesarrollo() {
  
        this.loading = true;
        const res = await this.updatePropiedad(this.propiedad);
  
        if (res) {
          this.$swal("Exito", "Propiedad actualizada", "success");
          this.loading = false;
          return this.$router.push({ name: "Admin Propiedades" });
        }
  
        this.$swal("Error", "Error al actualizar la propiedad", "error");
          this.loading = false;
  
      },
      removeImageLocal(index) {
        this.propiedad.galeria = incrementPositionAndRemove(
          this.propiedad.galeria,
          index
        );
        this.position--;
      },
      async removeImageDb(image, index) {
        const res = await this.deletePropiedadImage(image);
        if (res) {
          this.$swal("Exito", "Imagen eliminada", "success");
          this.propiedad.galeria.splice(index, 1);
          return 
        }
        this.$swal("Error", "Error al eliminar la imagen", "error");
      },
    },
  };
  </script>