<template>
  <div class="row col-12 d-flex align-items-center mt-2">
    <b-button type="submit" variant="primary" size="md">
      {{ id ? "Actualizar" : "Crear" }}
    </b-button>
  </div>
</template>

<script>
export default {
    props:{
        id:{},
    }
}
</script>